
  import {
    computed,
    defineAsyncComponent,
    defineComponent,
    onBeforeMount,
    PropType,
    ref,
  } from 'vue'
  import { isString } from 'lodash/fp'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import translate from '../../../util/misc/translate'
  import Icon from '../../common/icon/icon.vue'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import ClearCartOverlay from '../../common/overlay-types/clear-cart-overlay/clear-cart-overlay.vue'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import GenericInlineLoader from '../../common/generic-inline-loader/generic-inline-loader.vue'
  import { ExpectedDelivery } from '../../../types/paloma-api-checkout'
  import pluralize from '../../../util/strings/pluralize'
  import CartItem from './cart-item.vue'
  import Spinner from '../../common/spinner/spinner.vue'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import { OverlayIds } from '../../../constants/overlay-ids'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'
  import areDatesSameDay from '../../../util/misc/are-dates-same-day'
  import getTomorrowDate from '../../../util/misc/get-tomorrow-date'
  import { EnhancedOderItem } from '../../../hooks/use-cart/types'

  export default defineComponent({
    components: {
      CartCalendar: defineAsyncComponent(() => import('./cart-calendar.vue')),
      Spinner,
      CartItem,
      GenericInlineLoader,
      EmptyState,
      Icon,
      SimpleButton,
    },
    props: {
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['base'],
      },
    },
    setup(props) {
      const [cart, { getCart, purchase, deleteMultipleProducts }] = useCart()
      const [, { show }] = useSnackbar()
      const [, overlaysMethods] = useOverlay()
      const isUpdatingCartStatus = ref<boolean>(false)
      const isLoadingCart = ref<boolean>(true)
      const isDeletingProducts = ref<boolean>(false)
      const expectedDeliveries = computed(
        () => cart.value?.expectedDeliveries ?? [],
      )
      const hasExpectedDeliveries = computed(
        () =>
          expectedDeliveries.value.filter(
            (delivery) => delivery.isDefaultShipping === true,
          ).length > 0,
      )
      const hasDisabledDeliveries = computed(
        () =>
          expectedDeliveries.value.filter(
            (delivery) => delivery.isDisabled === true,
          ).length > 0,
      )
      const validExpectedDelivery = computed(() =>
        expectedDeliveries.value.find(
          (delivery) => delivery.isDefaultShipping === true,
        ),
      )
      const disabledDeliveries = computed(() =>
        expectedDeliveries.value.filter(
          (delivery) => delivery.isDisabled === true,
        ),
      )
      const dropshippingDeliveries = computed(() =>
        expectedDeliveries.value.filter(
          (delivery) => delivery.isDropShipping === true,
        ),
      )
      const isCartEnabled = computed(
        () =>
          isString(cart.value?.id) &&
          Boolean(cart.value?.id) &&
          cart.value?.items.length,
      )

      const onCartLoaded = () => {
        isLoadingCart.value = false
      }
      const onBeforeCartStatusUpdate = () => {
        isUpdatingCartStatus.value = true
      }
      const onCartStatusUpdated = () => {
        isUpdatingCartStatus.value = false
      }
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `cart-error-${new Date()}`,
        })
      }

      onBeforeMount(() => {
        getCart().catch(onError).finally(onCartLoaded)
      })

      return {
        translate,
        pluralize,
        cart,
        isLoadingCart,
        isUpdatingCartStatus,
        isDeletingProducts,
        isCartEnabled,
        expectedDeliveries,
        validExpectedDelivery,
        hasExpectedDeliveries,
        hasDisabledDeliveries,
        disabledDeliveries,
        dropshippingDeliveries,
        deleteProducts(items: EnhancedOderItem[]) {
          isDeletingProducts.value = true

          deleteMultipleProducts({
            itemIds: items.map(({ id }) => id) ?? [],
          })
            .catch(onError)
            .finally(() => {
              isDeletingProducts.value = false
            })
        },
        guessExpectedDeliveryTuQuantity(expectedDelivery: ExpectedDelivery) {
          return expectedDelivery?.quantities?.[0]?.quantity ?? 0
        },
        guessExpectedDeliveryCuQuantity(expectedDelivery: ExpectedDelivery) {
          return expectedDelivery?.quantities?.[1]?.quantity ?? 0
        },
        guessExpectedDeliveryPrice(expectedDelivery: ExpectedDelivery) {
          return expectedDelivery?.itemsPricing?.netPriceFormatted ?? 0
        },
        guessExpectedDeliveryCurrency(expectedDelivery: ExpectedDelivery) {
          return expectedDelivery?.itemsPricing?.currency ?? ''
        },
        guessExpectedDeliveryItemsAmount(expectedDelivery: ExpectedDelivery) {
          return expectedDelivery?.itemIds?.length ?? 0
        },
        guessExpectedDeliveryDate(expectedDelivery: ExpectedDelivery) {
          const date = expectedDelivery?.deliveryArrangement?.targetDate ?? ''

          if (!date) return ''

          const dateInstance = new Date(date)

          // needed to check if the date is tomorrow, in that case we create the "tomorrow" prefix
          const prefix = areDatesSameDay(dateInstance, getTomorrowDate())
            ? `${translate('shop__tomorrow')}, `
            : ''

          const dateToLocaleString = formatDateToLocaleDateString(
            dateInstance,
            {
              weekday: 'short',
            },
          )

          return `${prefix}${dateToLocaleString}`
        },
        showInvalidDeliveryDate(expectedDelivery: ExpectedDelivery) {
          const date = expectedDelivery?.deliveryArrangement?.targetDate ?? ''

          if (!date) return ''

          const dateInstance = new Date(date)
          return formatDateToLocaleDateString(dateInstance, {
            weekday: 'short',
          })
        },
        onClearCartClick() {
          overlaysMethods.open({
            id: OverlayIds.CLEAR_CART,
            component: ClearCartOverlay,
            props: {},
          })
        },
        onCheckoutClick() {
          const showSuccessMessage = () => {
            show({
              type: SnackbarType.SUCCESS,
              text: translate('shop__cart__cart_success__message'),
              id: `cart-success-${new Date()}`,
            })
          }

          onBeforeCartStatusUpdate()

          purchase()
            .then(showSuccessMessage)
            .catch(onError)
            .finally(onCartStatusUpdated)
        },
        getCartItemsByExpectedDelivery(expectedDelivery?: ExpectedDelivery) {
          return expectedDelivery && cart.value?.items
            ? cart.value?.items.filter(({ id }) =>
                expectedDelivery?.itemIds?.includes(id),
              )
            : []
        },
        rootClasses: computed(() =>
          props.facets.map((facet) => `cart-panel--${facet}`),
        ),
        hasProducts: computed(() => cart.value?.items.length),
      }
    },
  })
