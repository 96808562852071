import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "product-list-item product-list-item--list",
  ref: "root"
}
const _hoisted_2 = {
  key: 0,
  class: "product-list-item__wrap"
}
const _hoisted_3 = { class: "product-list-item__link u-reset" }
const _hoisted_4 = { class: "product-list-item__graphic-wrap" }
const _hoisted_5 = { class: "product-list-item__figure" }
const _hoisted_6 = ["alt", "src"]
const _hoisted_7 = { class: "product-list-item__info" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "product-list-item__title u-reset" }
const _hoisted_10 = {
  key: 1,
  class: "product-list-item__title u-reset"
}
const _hoisted_11 = {
  key: 2,
  class: "product-list-item__meta"
}
const _hoisted_12 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_13 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_14 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_15 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_16 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_17 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_18 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_19 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_20 = {
  key: 3,
  class: "product-list-item__features u-reset"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = { class: "product-list-item__meta product-list-item__meta--bottom u-reset" }
const _hoisted_24 = {
  key: 0,
  class: "product-list-item__meta-text"
}
const _hoisted_25 = {
  key: 1,
  class: "product-list-item__meta-text"
}
const _hoisted_26 = {
  key: 2,
  class: "product-list-item__meta-text product-list-item__meta-text--total-price"
}
const _hoisted_27 = {
  key: 0,
  class: "product-list-item__buttons"
}
const _hoisted_28 = {
  key: 1,
  class: "product-list-item__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_product_watchlist_button = _resolveComponent("product-watchlist-button")!
  const _component_product_counter = _resolveComponent("product-counter")!
  const _component_add_to_cart_button = _resolveComponent("add-to-cart-button")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("figure", _hoisted_5, [
                (_ctx.imageUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "product-list-item__image",
                      alt: _ctx.title,
                      src: _ctx.imageUrl
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.productUrl)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "u-reset",
                    href: _ctx.productUrl
                  }, [
                    _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.title), 1)
                  ], 8, _hoisted_8))
                : (_openBlock(), _createElementBlock("h2", _hoisted_10, _toDisplayString(_ctx.title), 1)),
              (_ctx.productNumber)
                ? (_openBlock(), _createElementBlock("dl", _hoisted_11, [
                    (_ctx.batchNumber)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("dt", _hoisted_12, _toDisplayString(_ctx.translate('shop__product__batchnr')) + ": ", 1),
                          _createElementVNode("dd", _hoisted_13, _toDisplayString(_ctx.batchNumber), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.expirationDate)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.translate('shop__product__expiration_date')) + ": ", 1),
                          _createElementVNode("dd", _hoisted_15, _toDisplayString(_ctx.expirationDate), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("dt", _hoisted_16, _toDisplayString(_ctx.translate('shop__product__artnr')) + ": ", 1),
                    _createElementVNode("dd", _hoisted_17, _toDisplayString(_ctx.productNumber), 1),
                    (_ctx.price)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("dt", _hoisted_18, _toDisplayString(_ctx.price.priceType) + ": ", 1),
                          _createElementVNode("dd", _hoisted_19, _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.totalPrice) + " / " + _toDisplayString(_ctx.priceSuffix), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.features.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_20, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "product-list-item__feature",
                        key: index
                      }, [
                        (feature.text)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(feature.text), 1))
                          : (feature.icon)
                            ? (_openBlock(), _createBlock(_component_icon, {
                                key: 1,
                                name: feature.icon.name,
                                size: feature.icon.size
                              }, null, 8, ["name", "size"]))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 2,
                                class: "product-list-item__feature-image",
                                src: feature.url,
                                alt: feature.title
                              }, null, 8, _hoisted_22))
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("ul", _hoisted_23, [
                (_ctx.quantityDescription)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_24, _toDisplayString(_ctx.quantityDescription), 1))
                  : _createCommentVNode("", true),
                (_ctx.cuPrice)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_25, _toDisplayString(_ctx.cuPrice.priceType) + ": " + _toDisplayString(_ctx.cuPrice.currency) + " " + _toDisplayString(_ctx.cuPrice.unitPrice) + " / " + _toDisplayString(_ctx.cuPrice.unit), 1))
                  : _createCommentVNode("", true),
                (_ctx.totalPrice)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_26, " EP: " + _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.totalPrice) + " / " + _toDisplayString(_ctx.priceSuffix), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          (_ctx.user.isAuthenticated && _ctx.price)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _renderSlot(_ctx.$slots, "watchlist-button", {}, () => [
                  _createVNode(_component_product_watchlist_button, {
                    "product-id": _ctx.productId,
                    unit: _ctx.unitCode
                  }, null, 8, ["product-id", "unit"])
                ]),
                (_ctx.showCounter)
                  ? (_openBlock(), _createBlock(_component_product_counter, {
                      key: 0,
                      "is-loading": _ctx.isCounterLoading,
                      "is-enabled": _ctx.isCounterEnabled,
                      value: _ctx.quantity,
                      onInput: _ctx.onQuantityUpdated
                    }, null, 8, ["is-loading", "is-enabled", "value", "onInput"]))
                  : (_openBlock(), _createBlock(_component_add_to_cart_button, {
                      key: 1,
                      "product-id": _ctx.productId,
                      unit: _ctx.unitCode
                    }, null, 8, ["product-id", "unit"]))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_28))
  ], 512))
}